<template>
  <div class="wx-container">
    <img class="logoIcon" :src="warnImg" >
  </div>
</template>

<script>
	import Api from "@/server";
	export default {
		data() {
			return {
        warnText: '',
        warnImg: '',
        sucImg: require('../../assets/images/wxlogin/hassusse.png'),
        errImg: require('../../assets/images/wxlogin/hasfild.png'),
      }
    },
    mounted() {
      let url = window.location.href,
          hasBind = Api.getUrlParams(url,'hasBind');
      if(hasBind == 'true'){
        this.warnImg = this.sucImg;
        // this.warnText = '绑定成功';
      }else{
        this.warnImg = this.errImg;
        // this.warnText = '绑定失败';
      }
    },
  }
</script>

<style lang="less" scoped>
  .wx-container{
    width: 100vw;
    height: 100vh;
    // background-image: url('../../assets/images/wxlogin/bg.png');
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding-top: 6vh;
	overflow: hidden;
    
    img{
		width: 100%;
		height: auto;
	}
    
    
    
    
    
    
    
    
  }
</style>
